<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Detail Asset" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select class="w-full" v-model="data.id_proyek" disabled>
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Group Asset *</label>
            <vs-input class="w-full" v-model="namaFixedAsset" disabled/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Nama Asset *</label>
            <vs-input class="w-full" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Qty *</label>
            <vs-input class="w-full" type="number" v-model="data.qty"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Satuan *</label>
            <vs-input class="w-full" v-model="data.satuan" placeholder="Unit / Pcs / Kotak"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Tgl. Perolehan *
              <abbr title="Tgl. Pembelian / Perolehan Dari Asset Tersebut">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <flat-pickr class="w-full" v-model="data.tgl_perolehan"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Tgl. Mulai Disusutkan *
              <abbr title="Tgl. Mulai Disusutkan Pada Sistem Dafis (Bukan Tgl. Mulai Disusutkan Pada Sistem Sebelumnya)">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <flat-pickr class="w-full" v-model="data.tgl_mulai_disusutkan"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Total Nilai Asset *
              <abbr title="Total Nilai Asset Setelah Dikali Qty">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <v-money class="w-38 text-right" type="text" v-model="data.total" placeholder="Rp."/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Long Life *
              <abbr title="Estimasi Umur Asset, Dalam Satuan Bulan">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <div class="flex items-center">
              <vs-input class="w-full" type="number" v-model="data.long_life" placeholder="__ Bulan"/>
            </div>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Jumlah Sudah Diproses *
              <abbr title="Jumlah Berapa Kali Asset Sudah Diproses Sebelumnya, Dalam Satuan Bulan">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <vs-input class="w-full" type="number" v-model="data.jml_proses" placeholder="__ Bulan"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">
              Total Sudah Diproses  *
              <abbr title="Total Nilai Asset Yang Sudah Diproses, Dalam Satuan Amount Rp.">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 text-warning" class="mr-1"/>
              </abbr>
            </label>
            <v-money class="w-38 text-right" type="text" :value="totalProcessed" placeholder="Rp." readonly/>
          </div>
        </div>

        <div class="vx-row mt-base">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import SetupFixedAssetRepository from '@/repositories/accounting/setup-fixed-asset-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'DetailAssetAdd',
  props: ['isActive', 'idFixedAsset', 'namaFixedAsset'],
  components: {
    ValidationErrors,
    flatPickr,
    VMoney
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      proyeks: [],
      data: {
        id_proyek: null,
        long_life: 0,
        jml_proses: 0,
        tgl_mulai_disusutkan: moment().format('YYYY-MM-DD')
      }
    }
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.setupFixedAsset.headerSection
    },
    totalProcessed () {
      return ((this.data.total || 0) / (this.data.long_life || 0)) * (this.data.jml_proses || 0)
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getAllGroups()
        this.data.id_proyek = this.dataHeaderSection.filter.id_proyek
      }
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllGroups () {
      SetupFixedAssetRepository.getGroups()
        .then(response => {
          this.groups = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      SetupFixedAssetRepository.createDetail(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_fixed_asset: this.idFixedAsset,
        id_proyek: this.data.id_proyek,
        nama: this.data.nama,
        satuan: this.data.satuan,
        qty: this.data.qty,
        total: this.data.total,
        tgl_perolehan: this.data.tgl_perolehan,
        tgl_mulai_disusutkan: this.data.tgl_mulai_disusutkan,
        long_life: this.data.long_life,
        jml_proses: this.data.jml_proses,
        total_proses: this.totalProcessed
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks', 'groups'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
